/* eslint-disable react/display-name */
import {
  Box,
  Flex,
  Text,
  Grid,
  Button,
  Icon,
  styled,
  Center,
  Spinner,
} from "@chakra-ui/react";
import React from "react";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronUp,
} from "react-feather";
import {
  Column,
  Row,
  usePagination,
  useSortBy,
  useTable,
  useFilters,
  useGlobalFilter,
} from "react-table";
import Card from "../Card";
import BottomSection from "../Card/CardFooter";
import TopSection from "../Card/CardHeader";
import { fuzzyTextFilterFn, GlobalFilter } from "./filter";
import {
  StyledTable,
  TableCell,
  TableHead,
  TableIconButton,
  TableRow,
} from "./styles";
import { Trans, useTranslation } from "next-i18next";
import { TableVirtuoso } from "react-virtuoso";
import { sortColumsOfStrings } from "@/utils/columnSort";

type TableProps<D extends object = {}> = {
  data: any;
  pageSize?: number;
  tableHeading?: React.ReactNode;
  columns: Column<D>[];
  onRowClick?: (row: Row<D>) => void;
  getRowId?: (row: Row<D>) => any;
  canFilter?: boolean;
  searchBar?: boolean;
  pagination?: boolean;
  pageCount?: number;
  count?: number;
  isLoading?: boolean;
  moreButton?: JSX.Element;
  variant?: "pagination" | "infinite";
  fetchData?: (params) => void;
};

const Table = <D extends {}>({
  columns,
  data,
  tableHeading,
  pageSize: initialPageSize,
  onRowClick,
  getRowId,
  canFilter,
  fetchData,
  pageCount: controlledPageCount,
  count: controlledCount,
  searchBar = true,
  moreButton = null,
  pagination = true,
  isLoading = false,
  variant = "infinite",
}: TableProps<D>): JSX.Element => {
  const { t } = useTranslation("common");
  const [showFilters, setShowFilters] = React.useState(false);
  const tableColumns = React.useMemo(() => columns, [columns]);
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter, sortBy },
  } = useTable<D>(
    {
      data,
      columns: tableColumns,
      manualPagination: !!fetchData,
      manualGlobalFilter: !!fetchData,
      manualSortBy: !!fetchData,
      autoResetPage: !fetchData,
      autoResetSortBy: false,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
      },
      pageCount: controlledPageCount,
      filterTypes,
      globalFilter: "fuzzyText",
      getRowId,
      sortTypes: { alphanumeric: sortColumsOfStrings },
      // defaultColumn,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const pageNr = pageIndex + 1;
  const pagesNr = pageOptions.length;

  React.useEffect(() => {
    fetchData && fetchData({ pageIndex, pageSize });
  }, [pageIndex, pageSize]);

  React.useEffect(() => {
    fetchData && fetchData({ search: globalFilter, sortBy });
    gotoPage(0);
  }, [globalFilter, sortBy]);

  return (
    <Card
      flexDirection="column"
      flex={{ base: "none", md: 1 }}
      width={{ base: "100%", md: "auto" }}
    >
      {canFilter && (
        <>
          <Button
            size="sm"
            variant="unstyled"
            borderRadius="0"
            outline="none"
            boxShadow="none"
            textAlign="left"
            width="100px"
            mb={5}
            onClick={() => setShowFilters(!showFilters)}
          >
            {t("filters")}
            <Icon as={showFilters ? ChevronUp : ChevronDown} size={20} ml={2} />
          </Button>
          {showFilters && (
            <Grid templateColumns="repeat(4, 1fr)" mb={5} gap={5}>
              {headerGroups.map((headerGroup) =>
                headerGroup.headers.map((column) => {
                  if (!column.Filter) return;
                  return (
                    <Box key={column.id}>
                      <Text fontWeight="bold" mb="5px">
                        {column.render("Header")}
                      </Text>
                      {column.canFilter ? column.render("Filter") : null}
                    </Box>
                  );
                })
              )}
            </Grid>
          )}
        </>
      )}
      {!!tableHeading && <TopSection>{tableHeading}</TopSection>}
      {searchBar && (
        <Flex flex={1} px={4} py={3} justifyContent="space-between">
          <GlobalFilter
            count={controlledCount || preGlobalFilteredRows.length}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Flex>
      )}
      <Box maxWidth="100%" width="100%" overflowX="auto" overflowY="hidden">
        {variant === "infinite" && (
          <TableVirtuoso
            key={fetchData && globalFilter}
            useWindowScroll
            totalCount={rows.length}
            components={{
              Table: ({ style, ...props }) => (
                <StyledTable
                  {...getTableProps()}
                  {...props}
                  style={{
                    ...style,
                    width: 800,
                    tableLayout: pagination ? "fixed" : "auto",
                  }}
                />
              ),
              TableBody: React.forwardRef(({ style, ...props }, ref) => (
                <Box
                  display="table-row-group"
                  {...getTableBodyProps()}
                  {...props}
                  ref={ref}
                />
              )),
              TableRow: (props) => {
                const index = props["data-index"];
                const row = rows[index];

                return (
                  <TableRow
                    onClick={() => onRowClick && onRowClick(row)}
                    key={row.id}
                    display="table-row"
                    {...props}
                    {...row.getRowProps()}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#edf2f7" : "#fff",
                    }}
                    data-testid="table-row"
                  />
                );
              },
            }}
            fixedHeaderContent={() => {
              return headerGroups.map((headerGroup) => (
                <Box
                  key={headerGroup.id}
                  display="table-row"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      px={4}
                      py={3}
                      key={column.id}
                      width={column.width || "unset"}
                      {...column.getHeaderProps()}
                      justifyContent="space-between"
                      {...column.getSortByToggleProps({ title: undefined })}
                    >
                      <Text textTransform="uppercase" fontWeight="500">
                        {column.render("Header")}
                      </Text>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronDown size={15} />
                        ) : (
                          <ChevronUp size={15} />
                        )
                      ) : (
                        ""
                      )}
                    </TableCell>
                  ))}
                </Box>
              ));
            }}
            itemContent={(index) => {
              const row = rows[index];
              prepareRow(row);

              return row.cells.map((cell) => {
                return (
                  <TableCell
                    key={cell.row.index}
                    justifyContent="flex-start"
                    px={4}
                    py={3}
                    maxWidth={cell.column.maxWidth}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                );
              });
            }}
            endReached={nextPage}
          />
        )}
        {variant === "pagination" && (
          <StyledTable {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <Box
                  key={headerGroup.id}
                  display="table-row"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      px={4}
                      py={3}
                      key={column.id}
                      maxWidth={column.maxWidth}
                      {...column.getHeaderProps()}
                      justifyContent="space-between"
                      {...column.getSortByToggleProps({ title: undefined })}
                    >
                      <Text textTransform="uppercase" fontWeight="500">
                        {column.render("Header")}
                      </Text>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronDown size={15} />
                        ) : (
                          <ChevronUp size={15} />
                        )
                      ) : (
                        ""
                      )}
                    </TableCell>
                  ))}
                </Box>
              ))}
            </TableHead>
            <Box display="table-row-group">
              {page.map(
                (row) =>
                  prepareRow(row) || (
                    <TableRow
                      onClick={() => onRowClick && onRowClick(row)}
                      key={row.id}
                      display="table-row"
                      {...row.getRowProps()}
                      data-testid="table-row"
                    >
                      {row.cells.map((cell) => {
                        return (
                          <TableCell
                            key={cell.row.index}
                            justifyContent="flex-start"
                            px={4}
                            py={3}
                            maxWidth={cell.column.maxWidth}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )
              )}
            </Box>
          </StyledTable>
        )}
        {isLoading && (
          <Center mt={2}>
            <Spinner />
          </Center>
        )}
      </Box>
      <BottomSection justifyContent="flex-end" flexDirection="row">
        {!pagination && moreButton}
        {pagination && variant === "pagination" && (
          <>
            <Flex flexDirection="row">
              <TableIconButton
                mx="2px"
                onClick={() => gotoPage(0)}
                isDisabled={!canPreviousPage}
                icon={<ChevronsLeft size={17} />}
              />
              <TableIconButton
                mx="2px"
                isDisabled={!canPreviousPage}
                onClick={() => previousPage()}
                icon={<ChevronLeft size={17} />}
              />
            </Flex>
            <Flex justifyContent="center" alignItems="center">
              <Text
                mx="7px"
                color="gray.500"
                fontSize="13px"
                textAlign="center"
                lineHeight="1.2"
              >
                <Trans i18nKey="pageNrOf">
                  Stron
                  <strong>
                    {{ pageNr }} z {{ pagesNr }}
                  </strong>
                </Trans>
              </Text>
            </Flex>
            <Flex flexDirection="row">
              <TableIconButton
                mx="2px"
                isDisabled={!canNextPage}
                onClick={() => nextPage()}
                icon={<ChevronRight size={17} />}
              />
              <TableIconButton
                mx="2px"
                onClick={() => gotoPage(pageCount ? pageCount - 1 : 1)}
                isDisabled={!canNextPage}
                icon={<ChevronsRight size={17} />}
              />
            </Flex>
          </>
        )}
      </BottomSection>
    </Card>
  );
};

export default React.memo(Table);

Table.defaultProps = {
  pageSize: 10,
};
