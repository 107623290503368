/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import {
  Box,
  Heading,
  Badge,
  BoxProps,
  Icon,
  Tooltip,
  Flex,
  IconButton,
  Center,
  Spinner,
  SimpleGrid,
} from "@chakra-ui/react";
import { ArrowUpCircle, ArrowDownCircle, Check, X } from "react-feather";
import useSWR, { mutate } from "swr";
import { useTranslation } from "next-i18next";
import axios from "axios";

import Table from "@/components/common/Table";
import BoxCard from "@/components/common/BoxCard";
import User from "@/components/common/UserOfficeBadge";
import { mutateSession } from "@/hooks/useUser";
import { useToastPromise } from "@/hooks/useToast";

const Invitations = ({ ...props }: BoxProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const toast = useToastPromise();

  const { data: officeRequests, error: errorRequest } = useSWR(
    "/office-request/requests"
  );
  const { data: officeInvitations, error: errorInvitations } = useSWR(
    "/office-request/invitations"
  );
  const isLoading =
    !officeRequests && !officeInvitations && !errorRequest && !errorInvitations;

  const allInvitations = useMemo(
    () =>
      (officeRequests || [])
        .concat(officeInvitations || [])
        .filter((request) => request.status === "waiting"),
    [officeRequests, officeInvitations]
  );

  const requestAction = (id, status) => {
    return toast.promise(
      axios
        .put(`/office-request/request/${id}`, {
          status,
        })
        .then(() => {
          mutate("/office-request/invitations");
          if (status === "accept") mutateSession();
        }),
      {
        errorKeys: {
          InvitationDoesNotExists: t("errorKeys.invitationDoesNotExists"),
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: t("name"),
        accessor: "editorialOffice.name",
        Cell: function Cell({ value, row }) {
          return (
            <User
              id={
                value
                  ? row?.original?.editorialOffice?.id
                  : row?.original?.pressOffice?.id
              }
              picture={
                value
                  ? row?.original?.editorialOffice?.picture
                  : row?.original?.pressOffice?.picture
              }
              name={value ? value : row?.original?.pressOffice?.name}
              type={value ? "editorial" : "press"}
              website={row?.original?.pressOffice?.website}
              url={
                value
                  ? row?.original?.editorialOffice?.url
                  : row?.original?.pressOffice?.url
              }
            />
          );
        },
      },
      {
        Header: t("events.status"),
        accessor: "status",
        Cell: ({ value }) => <Badge variant={"solid"}>{t(value)}</Badge>,
      },
      {
        Header: t("settings.type"),
        accessor: "type",
        Cell: ({ value, row }) => (
          <Flex align="center" justify="space-between">
            <Tooltip
              label={
                value === "request"
                  ? t("newsletter.menuSentOption")
                  : t("received")
              }
            >
              <Icon
                as={value === "request" ? ArrowUpCircle : ArrowDownCircle}
                w={6}
                h={6}
              />
            </Tooltip>
            {value === "invitation" && (
              <Flex justifyContent="flex-end" ml={5}>
                <IconButton
                  aria-label={t("accept")}
                  variant="green"
                  size="sm"
                  mr={2}
                  icon={<Check />}
                  onClick={() => requestAction(row.original.id, "accept")}
                />
                <IconButton
                  aria-label={t("reject")}
                  variant="red"
                  size="sm"
                  icon={<X />}
                  onClick={() => requestAction(row.original.id, "reject")}
                />
              </Flex>
            )}
          </Flex>
        ),
      },
    ],
    []
  );

  if (!isLoading && allInvitations?.length === 0) return null;

  return (
    <Box {...props} w="100%">
      <Heading as="h2" size="lg">
        {t("office.invitations")}
      </Heading>
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {!isLoading && (
        <BoxCard
          p={0}
          bg={{ base: "transparent", md: "white" }}
          boxShadow={{ base: "none", md: "0 3px 20px rgba(0,0,0,0.04)" }}
        >
          <Box d={{ base: "none", md: "block" }}>
            <Table
              columns={columns}
              data={allInvitations || []}
              searchBar={false}
              pagination={false}
            />
          </Box>
          <SimpleGrid columns={1} spacing={3} d={{ base: "grid", md: "none" }}>
            {allInvitations?.map((row) => (
              <BoxCard p={0} key={row.id}>
                <Flex
                  align="center"
                  justify="space-between"
                  p="1.25rem"
                  borderBottomColor="light.50"
                  borderBottomWidth="1px"
                >
                  <Box>
                    <User
                      id={
                        row?.editorialOffice?.name
                          ? row?.editorialOffice?.id
                          : row?.pressOffice?.id
                      }
                      picture={
                        row?.editorialOffice?.name
                          ? row?.editorialOffice?.picture
                          : row?.pressOffice?.picture
                      }
                      name={
                        row?.editorialOffice?.name
                          ? row?.editorialOffice?.name
                          : row?.pressOffice?.name
                      }
                      type={row?.editorialOffice?.name ? "editorial" : "press"}
                      website={row?.pressOffice?.website}
                      url={
                        row?.editorialOffice?.name
                          ? row?.editorialOffice?.url
                          : row?.pressOffice?.url
                      }
                      avatarSize="md"
                    >
                      <Badge
                        variant={"solid"}
                        mt={1}
                        fontSize="10px"
                        px="5.5px"
                        py="0"
                      >
                        {t(row.status)}
                      </Badge>
                    </User>
                  </Box>
                </Flex>
                <Flex
                  p="1.25rem"
                  justifyContent="space-between"
                  fontSize="20px"
                  align="center"
                >
                  <Tooltip
                    label={
                      row.type === "request"
                        ? t("newsletter.menuSentOption")
                        : t("received")
                    }
                  >
                    <Icon
                      as={
                        row.type === "request" ? ArrowUpCircle : ArrowDownCircle
                      }
                      w={6}
                      h={6}
                    />
                  </Tooltip>
                  {row.type === "invitation" && (
                    <Flex justifyContent="flex-end" ml={5}>
                      <IconButton
                        aria-label={t("accept")}
                        variant="green"
                        size="sm"
                        mr={2}
                        icon={<Check />}
                        onClick={() => requestAction(row.id, "accept")}
                      />
                      <IconButton
                        aria-label={t("reject")}
                        variant="red"
                        size="sm"
                        icon={<X />}
                        onClick={() => requestAction(row.id, "reject")}
                      />
                    </Flex>
                  )}
                </Flex>
              </BoxCard>
            ))}
          </SimpleGrid>
        </BoxCard>
      )}
    </Box>
  );
};

export default Invitations;
