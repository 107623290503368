import React from "react";
import { Box, Center, Flex, Heading, Spinner, Text } from "@chakra-ui/react";
import { filesize } from "filesize";
import { useTranslation } from "next-i18next";
import { Mail, Send, UploadCloud } from "react-feather";
import useSWRImmutable from "swr/immutable";

import BoxCard from "@/components/common/BoxCard";

const SuperadminLimits = (): React.ReactElement => {
  const { t } = useTranslation("common");
  const { data: stats, error } = useSWRImmutable("/app/stats");
  const isLoading = !stats && !error;

  if (isLoading) {
    return (
      <Box mt={5}>
        <Heading as="h3" size="lg">
          {t("payments.admin.usageStats")}
        </Heading>
        <Center>
          <Spinner />
        </Center>
      </Box>
    );
  }

  return (
    <>
      {stats && (
        <Box mt={5}>
          <Heading as="h3" size="lg">
            {t("payments.admin.usageStats")}
          </Heading>
          <BoxCard>
            <Flex direction="column" justifyContent="space-around" gap={2}>
              <Flex flexDirection={"column"} alignItems="center">
                <Flex gap={2} alignItems="center">
                  <Send />
                  <Text fontWeight={"bold"}>
                    {t("payments.admin.totalUsageSMS")}
                  </Text>
                </Flex>
                <Text fontWeight={"bold"} fontSize="xl" mt={1}>
                  {stats?.sms}
                </Text>
                {stats?.smsLeft && (
                  <Text mt={1}>
                    {t("payments.admin.smsLeft", {
                      value: stats.smsLeft,
                    })}
                  </Text>
                )}
              </Flex>
              <Flex flexDirection={"column"} alignItems="center" mt={3}>
                <Flex gap={2} alignItems="center">
                  <Mail />
                  <Text fontWeight={"bold"}>
                    {t("payments.admin.monthlyUsageEmail")}
                  </Text>
                </Flex>
                <Text fontWeight={"bold"} fontSize="xl" mt={1}>
                  {stats?.emails}
                </Text>
                {stats?.emailLimit && (
                  <Text mt={1}>
                    {t("payments.admin.mailLimit")} {stats.emailLimit}
                  </Text>
                )}
              </Flex>
              <Flex flexDirection={"column"} alignItems="center" mt={3}>
                <Flex gap={2} alignItems="center">
                  <UploadCloud />
                  <Text fontWeight={"bold"}>
                    {t("payments.admin.totalUsageCloudSpace")}
                  </Text>
                </Flex>
                <Text fontWeight={"bold"} fontSize="xl" mt={1}>
                  {filesize(stats?.cloud, {
                    base: 2,
                    standard: "jedec",
                  })}
                </Text>
                <Text mt={1}>
                  {t("payments.admin.diskSpaceLeft", {
                    value: filesize(stats?.diskSpaceLeft, {
                      base: 2,
                      standard: "jedec",
                    }),
                  })}
                </Text>
              </Flex>
            </Flex>
          </BoxCard>
        </Box>
      )}
    </>
  );
};

export default SuperadminLimits;
