import DashboardSubscribedEvents from "@/components/Accreditation";
import { useUser } from "@/hooks/useUser";
import { OfficeType } from "@/types/office";
import { Grid, GridItem, SimpleGrid, VStack } from "@chakra-ui/react";
import React from "react";
import DashboardApplications from "./Applications";
import DashboardCalendar from "./Calendar";
import DashboardEvents from "./Events";
import DashboardReporterInvitations from "./Invitations";
import DashboardLatestOffices from "./LatestOffices";
import DashboardNews from "./News";
import ReporterAlerts from "./ReporterAlerts";
import DashboardReporters from "./Reporters";
import DashboardSubscription from "./Subscription";
import SuperadminLimits from "./SuperadminLimits";
import DashboardTeam from "./Team";
import OrganizationEvents from "./OrganizationEvents";

const Dashboard = (): React.ReactElement => {
  const [session] = useUser();

  return (
    <>
      {session?.currentRole?.type === OfficeType.PRESS && (
        <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={5}>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <DashboardApplications />
            <DashboardSubscription />
          </GridItem>
          <GridItem>
            <SimpleGrid columns={1} spacing={5}>
              <DashboardTeam />
              <DashboardCalendar />
              <DashboardEvents />
            </SimpleGrid>
          </GridItem>
        </Grid>
      )}
      {session?.currentRole?.type === OfficeType.EDITORIAL && (
        <DashboardReporters />
      )}
      {session?.currentRole?.type !== OfficeType.PRESS &&
        session?.currentRole?.type !== OfficeType.EDITORIAL && (
          <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={5}>
            <GridItem>
              <DashboardNews
                organization={
                  session?.currentRole?.type === OfficeType.ORGANIZATION
                }
              />
              {session?.currentRole?.type === OfficeType.ORGANIZATION && (
                <DashboardSubscription />
              )}
              {session?.currentRole?.role === "superadmin" && (
                <SuperadminLimits />
              )}
            </GridItem>

            <GridItem colSpan={{ base: 1, md: 2 }}>
              <ReporterAlerts mb={3} />
              <VStack spacing="1.5rem">
                {session?.currentRole?.type === OfficeType.ORGANIZATION ? (
                  <OrganizationEvents />
                ) : (
                  <DashboardSubscribedEvents subscribedEventsOnly />
                )}
                {session?.currentRole?.type !== OfficeType.ORGANIZATION && (
                  <DashboardLatestOffices />
                )}
                <DashboardReporterInvitations maxW="calc(100vw - 56px)" />
                <DashboardCalendar />
              </VStack>
            </GridItem>
          </Grid>
        )}
    </>
  );
};

export default Dashboard;
