import withAuthentication from "@/components/withAuthentication";
import { getSession } from "next-auth/client";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React from "react";
import Layout from "@/components/Layout";
import Dashboard from "@/components/Dashboard";
import { TFunction } from "next-i18next";
import { GetServerSideProps } from "next";

export const Home = (): React.ReactElement => {
  return <Dashboard />;
};

Home.getLayout = function getLayout(page: React.ReactElement, t: TFunction) {
  return (
    <Layout
      title={t("users.dashboard")}
      breadcrumb={[
        { label: t("users.dashboard"), isCurrentPage: true, icon: Home },
      ]}
    >
      {page}
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale, ["common"])),
      session: await getSession(ctx),
      protected: true,
    },
  };
};

export default withAuthentication(Home);
