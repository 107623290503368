import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormInputs = {
  response: string;
};

type Props = {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: (id, status, response) => void;
};

const ResponseModal = ({
  id,
  isOpen,
  onClose,
  onSave,
}: Props): React.ReactElement => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<FormInputs>();
  const { t } = useTranslation("common");

  const onSubmit = ({ response }) => {
    onSave(id, "reject", response);
  };

  return (
    <Modal
      motionPreset="slideInBottom"
      isOpen={isOpen}
      onClose={onClose}
      preserveScrollBarGap
    >
      <ModalOverlay>
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <ModalHeader>{t("reject")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel htmlFor="response" mb="0px">
                  {t("message")}
                </FormLabel>
                <Textarea type="text" {...register("response")} autoFocus />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" onClick={onClose}>
                {t("cancel")}
              </Button>
              <Button
                type="submit"
                colorScheme="red"
                isLoading={isSubmitting}
                ml={3}
              >
                {t("reject")}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ResponseModal;
