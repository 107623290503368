import React, { useMemo } from "react";
import {
  Center,
  Spinner,
  Flex,
  Heading,
  Text,
  Box,
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Portal,
} from "@chakra-ui/react";
import Link from "@/components/common/Link";
import { CheckSquare, FileText } from "react-feather";
import { format } from "date-fns";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import useSWR, { mutate } from "swr";

import { useUser } from "@/hooks/useUser";
import useIsMobile from "@/hooks/useIsMobile";
import Table from "@/components/common/Table";
import BoxCard from "@/components/common/BoxCard";
import Avatar from "@/components/common/Avatar";
import User from "@/components/common/UserOfficeBadge";
import {
  getAccreditationStatusColor,
  getAccreditationStatusIcon,
} from "@/utils/accreditation";
import EventDescription from "@/components/Events/EventDescription";
import { AccreditationTypes } from "@/types/accreditation";

const OrganizationEvents = (): React.ReactElement => {
  const { t } = useTranslation("common");
  const [session] = useUser();
  const router = useRouter();
  const {
    data: events = [],
    error,
    mutate: refreshEvents,
  } = useSWR(`/event/organization?active=true`);
  const isMobile = useIsMobile();

  const update = () => {
    refreshEvents();
    mutate("/user/press-office");
    mutate("/press-office");
  };

  const eventColumns = useMemo(
    () => [
      {
        Header: t("pressOffice.pressOffice"),
        accessor: "pressOffice.name",
        Cell: function Cell({ value, row }) {
          return (
            <User
              type="press"
              name={value}
              id={row?.original?.pressOffice?.id}
              picture={row?.original?.pressOffice?.picture}
              website={row?.original?.pressOffice?.website}
              url={row?.original?.pressOffice?.url}
              subscribed={!!row?.original?.pressOffice?.subscribers?.length}
              onUpdate={update}
              showOfficeProfileIcon={false}
            />
          );
        },
      },
      {
        Header: t("events.event"),
        accessor: "name",
        Cell: ({ row }) => (
          <Flex align="center">
            <Popover trigger="hover" isLazy>
              <PopoverTrigger>
                <Avatar
                  src={
                    row.original.picture
                      ? `/uploads/${row.original.picture}`
                      : null
                  }
                  size="sm"
                  mr={4}
                  name={row.original.name}
                />
              </PopoverTrigger>
              {row?.original?.description && (
                <Portal>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader>
                      <Flex flex="1" alignItems="center">
                        <Avatar
                          src={
                            row.original.picture
                              ? `/uploads/${row.original.picture}`
                              : null
                          }
                          size="sm"
                          name={row.original.name}
                        />
                        <Text ml={3} fontWeight="500">
                          {row.original.name}
                        </Text>
                      </Flex>
                    </PopoverHeader>
                    <PopoverBody>
                      <Text textAlign={"justify"}>
                        <EventDescription event={row.original} />
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              )}
            </Popover>
            <Text fontWeight="500">{row.original.name}</Text>
          </Flex>
        ),
      },
      {
        Header: t("settings.start"),
        accessor: "startDate",
        Cell: ({ value }) => (
          <Text>{format(new Date(value), "dd/MM/yyyy")}</Text>
        ),
      },
      {
        Header: t("settings.end"),
        accessor: "endDate",
        Cell: ({ value }) => (
          <Text>{format(new Date(value), "dd/MM/yyyy")}</Text>
        ),
      },
      {
        id: "event",
        accessor: "id",
        disableSortBy: true,
        Cell: ({ value, row }) => {
          const now = new Date();
          const startAccreditation =
            row?.original?.accreditationFrom &&
            new Date(row.original.accreditationFrom);
          const endAccreditation =
            row?.original?.accreditationTo &&
            new Date(row.original.accreditationTo);

          const submittedAcreditation = row?.original?.accreditations?.find(
            (accreditation) => accreditation?.userId === session?.user?.id
          );

          return (
            <Flex
              width="100%"
              justifyContent="flex-end"
              fontSize="15px"
              direction="column"
            >
              {submittedAcreditation ? (
                <Button
                  bgColor={getAccreditationStatusColor(
                    submittedAcreditation?.status
                  )}
                  size="sm"
                  _hover={{
                    bgColor: getAccreditationStatusColor(
                      submittedAcreditation?.status
                    ),
                  }}
                  _focus={{
                    bgColor: getAccreditationStatusColor(
                      submittedAcreditation?.status
                    ),
                  }}
                  onClick={() => {
                    if (
                      submittedAcreditation?.status ===
                        AccreditationTypes.PENDING ||
                      submittedAcreditation?.status ===
                        AccreditationTypes.INCOMPLETE
                    )
                      router.push(`/accreditation/event/${value}`);
                    else return null;
                  }}
                >
                  <Icon
                    as={getAccreditationStatusIcon(
                      submittedAcreditation?.status
                    )}
                    w={5}
                    h={5}
                    mr={2}
                  />
                  {t(`events.statusInfo.${submittedAcreditation?.status}`)}
                </Button>
              ) : (
                <>
                  {!row?.original?.accreditationActive && startAccreditation && (
                    <Text
                      color="text.500"
                      fontSize="xs"
                      mt={1}
                      textAlign="left"
                    >
                      {t("events.accreditationFrom", {
                        date: format(startAccreditation, "dd/MM/yyyy HH:mm"),
                      })}
                    </Text>
                  )}
                  {!row?.original?.accreditationActive &&
                    !startAccreditation &&
                    !endAccreditation && (
                      <Text
                        color="text.500"
                        fontSize="xs"
                        mt={1}
                        textAlign="left"
                      >
                        {t("events.noAccreditationPeriod")}
                      </Text>
                    )}
                  {row?.original?.accreditationActive && endAccreditation && (
                    <>
                      <Button
                        size="sm"
                        onClick={() =>
                          router.push(`/accreditation/event/${value}`)
                        }
                      >
                        <Icon as={CheckSquare} w={5} h={5} mr={2} />
                        {t("events.accredit")}
                      </Button>
                      <Text
                        color="text.500"
                        fontSize="xs"
                        mt={1}
                        textAlign="left"
                      >
                        {t("events.accreditationTo", {
                          date: format(endAccreditation, "dd/MM/yyyy HH:mm"),
                        })}
                      </Text>
                    </>
                  )}
                </>
              )}
            </Flex>
          );
        },
      },
    ],
    []
  );

  const eventsOnMobile = useMemo(
    () =>
      events?.map((row) => {
        const submittedAcreditation = row?.accreditations?.find(
          (accreditation) => accreditation?.userId === session?.user?.id
        );

        return (
          <BoxCard p={0} key={row?.id}>
            <Flex
              align="center"
              justify="space-between"
              p="1.25rem"
              borderBottomColor="light.50"
              borderBottomWidth="1px"
            >
              <User
                type="press"
                name={row.pressOffice.name}
                id={row?.pressOffice?.id}
                picture={row?.pressOffice?.picture}
                website={row?.pressOffice?.website}
                url={row?.pressOffice?.url}
                subscribed={!!row?.pressOffice?.subscribers?.length}
                onUpdate={update}
                showOfficeProfileIcon={false}
                avatarSize="md"
              />
            </Flex>
            <Box p="1.25rem">
              <Flex align="center">
                <Avatar
                  name={row.name}
                  size="sm"
                  mr={3}
                  src={`/uploads/${row?.picture}`}
                />
                <Box>
                  <Text fontWeight="500">{row.name}</Text>
                  <Text color="text.500" fontSize="xs">
                    {format(new Date(row.startDate), "dd/MM/yyyy")}
                    &nbsp;-&nbsp;
                    {format(new Date(row.endDate), "dd/MM/yyyy")}
                  </Text>
                </Box>
              </Flex>
              {submittedAcreditation ? (
                <Button
                  bgColor={getAccreditationStatusColor(
                    submittedAcreditation?.status
                  )}
                  size="sm"
                  _hover={{
                    bgColor: getAccreditationStatusColor(
                      submittedAcreditation?.status
                    ),
                  }}
                  _focus={{
                    bgColor: getAccreditationStatusColor(
                      submittedAcreditation?.status
                    ),
                  }}
                  mt={3}
                  onClick={() => {
                    if (
                      submittedAcreditation?.status ===
                        AccreditationTypes.PENDING ||
                      submittedAcreditation?.status ===
                        AccreditationTypes.INCOMPLETE
                    )
                      router.push(`/accreditation/event/${row?.id}`);
                    else return null;
                  }}
                >
                  <Icon
                    as={getAccreditationStatusIcon(
                      submittedAcreditation?.status
                    )}
                    w={5}
                    h={5}
                    mr={2}
                  />
                  {t(`events.statusInfo.${submittedAcreditation?.status}`)}
                </Button>
              ) : (
                <Link to={`/accreditation/event/${row.id}`}>
                  <Button size="sm" mt={3}>
                    <Icon as={CheckSquare} w={5} h={5} mr={2} />
                    {t("events.accredit")}
                  </Button>
                </Link>
              )}
            </Box>
          </BoxCard>
        );
      }),
    [events]
  );

  return (
    <Box w="100%" maxW={{ base: "calc(100vw - 56px)", md: "100%" }} zIndex={9}>
      <Heading as="h2" size="lg">
        {t("events.organizationEvents")}
      </Heading>
      <BoxCard
        p={0}
        w="100%"
        bg={{ base: "transparent", md: "white" }}
        boxShadow={{ base: "none", md: "0 3px 20px rgba(0,0,0,0.04)" }}
      >
        {!events && !error && (
          <Center>
            <Spinner />
          </Center>
        )}
        {!error && events?.length > 0 && (
          <>
            {!isMobile && (
              <Table
                columns={eventColumns}
                data={events || []}
                searchBar={false}
                pagination={false}
                variant="pagination"
              />
            )}
            {isMobile && (
              <SimpleGrid
                columns={1}
                spacing={3}
                d={{ base: "grid", md: "none" }}
              >
                {eventsOnMobile}
              </SimpleGrid>
            )}
          </>
        )}
        {!error && events?.length === 0 && (
          <Box p={3} textAlign="center">
            <Text fontWeight="500">{t("events.noEvents")}</Text>
            <Text px={2} fontSize="1.125rem" mt="1.25rem" opacity="0.7">
              {t("events.noEventsSubtitleOrganization")}
            </Text>
            <Link to="/offices">
              <Button leftIcon={<FileText />} variant="solid" size="md" mt={5}>
                {t("pressOffice.pressOffices")}
              </Button>
            </Link>
          </Box>
        )}
      </BoxCard>
    </Box>
  );
};

export default OrganizationEvents;
