import React, { useMemo } from "react";
import { Heading, Box, Text, Button, Center, Spinner } from "@chakra-ui/react";
import Link from "@/components/common/Link";
import { useUser } from "@/hooks/useUser";
import BoxCard from "@/components/common/BoxCard";
import { useTranslation } from "next-i18next";
import Events from "@/components/Events";
import Map from "@/components/common/Map";
import useSWR from "swr";
import { Square } from "react-feather";

const DashboardEvents = ({ ...rest }) => {
  const { t } = useTranslation("common");
  const [session] = useUser();
  const currentOffice = session?.user?.currentOffice;

  const { data: events, error } = useSWR(
    currentOffice?.id ? `/event/office/${currentOffice.id}?active=true` : null
  );
  const isLoading = !events && !error;

  const markers = useMemo(
    () =>
      events?.map((event) => {
        const eventsLocations = event.locations.map((location) => {
          return {
            lat: location.lat,
            lng: location.lng,
          };
        });

        return eventsLocations.reduce(
          (obj, item) => Object.assign(obj, { lat: item.lat, lng: item.lng }),
          {}
        );
      }),
    [events]
  );

  return (
    <Box {...rest}>
      <Heading as="h3" size="lg">
        {t("events.events")}
      </Heading>
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {!isLoading && events?.length > 0 && markers?.length > 0 && (
        <BoxCard>
          <Box>
            <Map zoom={13} scrollWheelZoom={false} markers={markers} />
          </Box>
          <Events list />
        </BoxCard>
      )}
      {!isLoading && events?.length === 0 && (
        <BoxCard p={3} textAlign="center">
          <Text fontWeight="500">{t("events.noEvents")}</Text>
          <Text px={2} fontSize="1.125rem" mt="1.25rem" opacity="0.7">
            {t("events.noEventsListSubtitle")}
          </Text>
          <Link to="/events/add">
            <Button leftIcon={<Square />} variant="solid" size="md" mt={5}>
              {t("events.addEvent")}
            </Button>
          </Link>
        </BoxCard>
      )}
    </Box>
  );
};

export default DashboardEvents;
