import React, { useMemo } from "react";
import {
  Flex,
  Heading,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar as BigCalendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { Event as EventType } from "@/types/event";
import BoxCard from "@/components/common/BoxCard";
import Avatar from "@/components/common/Avatar";
import { Calendar as CalendarIcon, MapPin } from "react-feather";
import useSWR from "swr";
import { useSession } from "next-auth/client";
import Link from "@/components/common/Link";
import { useUser } from "@/hooks/useUser";

import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-GB";
import ru from "date-fns/locale/ru";
import {
  endOfMonth,
  isMonday,
  isSunday,
  nextSunday,
  previousMonday,
  startOfMonth,
} from "date-fns";

const locales = {
  pl: pl,
  en: en,
  ru: ru,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Calendar = ({
  small = false,
  customEvents = null,
}: {
  small?: boolean;
  customEvents?: {
    data: EventType[];
  };
}): React.ReactElement => {
  const [range, setRange] = React.useState(() => {
    const currentDay = new Date();
    const firstDay = startOfMonth(currentDay);
    const lastDay = endOfMonth(currentDay);
    const start = isMonday(firstDay) ? firstDay : previousMonday(firstDay);
    const end = isSunday(lastDay) ? lastDay : nextSunday(lastDay);

    return { start, end };
  });
  const { t } = useTranslation("common");
  const [session] = useSession();
  const { data: events = customEvents, error } = useSWR(
    !customEvents
      ? `/event?start=${range.start.toISOString()}&end=${range.end.toISOString()}`
      : null
  );
  const isLoading = !events && !error;

  const messages = {
    allDay: t("calendar.allDay"),
    previous: t("calendar.previous"),
    next: t("calendar.next"),
    today: t("calendar.today"),
    month: t("calendar.month"),
    week: t("calendar.week"),
    day: t("calendar.day"),
    date: t("events.date"),
    time: t("time"),
    event: t("events.event"),
    noEventsInRange: t("calendar.noEventsInRange"),
    work_week: t("calendar.workWeek"),
    yesterday: t("calendar.yesterday"),
    tomorrow: t("calendar.tomorrow"),
    showMore: function showMore(total) {
      return `+ ${total} ${t("more")}`;
    },
  };

  const calendarEvents = useMemo(
    () =>
      Object.values(
        events?.reduce((events, event) => {
          const startDate = new Date(event.startDate);

          for (
            let day = new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate()
            );
            day <= new Date(event.endDate);
            day.setDate(day.getDate() + 1)
          ) {
            const formattedDay = format(new Date(day), "dd/MM/yyyy");

            if (!events?.[formattedDay]) {
              events = {
                ...events,
                [formattedDay]: {
                  start: new Date(day),
                  end: new Date(day),
                  allDay: true,
                  events: [event],
                },
              };
            } else {
              events[formattedDay].events.push(event);
            }
          }

          return events;
        }, {}) ?? {}
      ),
    [events]
  );

  const formats = {
    weekdayFormat: small ? "EE" : "EEEE",
    monthHeaderFormat: "LLLL yyyy",
  };

  return (
    <>
      {!small && (
        <Heading as="h2" size="lg">
          {t("calendar.calendar")}
          {isLoading && <Spinner ml="2" size="xs" />}
        </Heading>
      )}
      <BoxCard>
        <BigCalendar
          localizer={localizer}
          events={calendarEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: small ? 400 : 600 }}
          messages={messages}
          culture={session?.user?.profile?.locale}
          views={["month"]}
          formats={formats}
          eventPropGetter={EventPropGetter}
          onRangeChange={(range) => setRange(range)}
          components={{ event: Event(small) }}
        />
      </BoxCard>
    </>
  );
};

type CalendarEvent = {
  events: EventType[];
  start: Date;
  end: Date;
  allDay: boolean;
};

const EventPropGetter = () => ({
  style: {
    background: "transparent",
  },
});

const Event = (small) => {
  const CalendarEvent = ({ event }: { event: CalendarEvent }) => {
    const { t } = useTranslation("common");
    const [session] = useUser();
    const currentRole = session?.currentRole;
    const roleBasedSmallShow = currentRole?.type === "user" ? 2 : 1;
    const eventsToShow = useMemo(
      () => event?.events?.slice(0, small ? roleBasedSmallShow : 2),
      [event, small, roleBasedSmallShow]
    );

    return (
      <Popover trigger="hover" isLazy lazyBehavior="unmount">
        <PopoverTrigger>
          <Flex justify="center">
            <HStack spacing={small ? "-.3rem" : "-.5rem"} mt="-10%">
              {eventsToShow.map((event) => (
                <Avatar
                  src={event.picture ? `/uploads/${event.picture}` : null}
                  size={small ? "xs" : "md"}
                  boxShadow="0 0 transparent,0 0 transparent,0 0 5px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)"
                  name={event.name}
                  key={event.id}
                />
              ))}
              {event?.events?.length > (small ? roleBasedSmallShow : 2) && (
                <Avatar
                  src={null}
                  size={small ? "xs" : "md"}
                  boxShadow="0 0 transparent,0 0 transparent,0 0 5px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05)"
                  name="…"
                  color={"gray.600"}
                  bgColor="gray.300"
                  opacity={"0.9"}
                />
              )}
            </HStack>
          </Flex>
        </PopoverTrigger>

        <Portal>
          <PopoverContent overflowY="auto" maxHeight="400px">
            {event?.events?.map((event) => (
              <React.Fragment key={event?.id}>
                <PopoverArrow />
                <PopoverHeader d="flex" alignItems="center">
                  <Link to={`/accreditation/event/${event.id}`}>
                    <Flex align={"center"}>
                      <Avatar
                        src={event.picture ? `/uploads/${event.picture}` : null}
                        size={"2xs"}
                        name={event.name}
                        mr={2}
                      />
                      <Text>{event.name}</Text>
                    </Flex>
                  </Link>
                </PopoverHeader>
                <PopoverBody mb={2}>
                  <Text
                    color="text.500"
                    fontSize="md"
                    d="flex"
                    alignItems="center"
                  >
                    <Icon as={MapPin} mr={2} />
                    {event.locations
                      .map((location) => location.name)
                      .join(", ")}
                  </Text>
                  <Text color="gray" mt={1} d="flex" alignItems="center">
                    <Icon as={CalendarIcon} mr={2} />(
                    {format(new Date(event.startDate), "dd/MM/yyyy")}
                    &nbsp;-&nbsp;
                    {format(new Date(event.endDate), "dd/MM/yyyy")})
                  </Text>
                  <Link
                    to={`/offices/office/${event?.pressOffice?.id}`}
                    color={"gray"}
                  >
                    <Flex mt={1} align="center">
                      <Avatar
                        src={
                          event?.pressOffice?.picture
                            ? `/uploads/${event?.pressOffice?.picture}`
                            : null
                        }
                        size={"2xs"}
                        name={event?.pressOffice?.name}
                      />
                      <Text ml={2}>{event?.pressOffice?.name}</Text>
                      <Text fontSize={"xs"} color="gray.400" ml={1}>
                        ({t("events.organizer")})
                      </Text>
                    </Flex>
                  </Link>
                </PopoverBody>
              </React.Fragment>
            ))}
          </PopoverContent>
        </Portal>
      </Popover>
    );
  };

  return CalendarEvent;
};

export default Calendar;
