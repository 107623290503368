import React from "react";
import { Box } from "@chakra-ui/react";
import { UserInvitationStatuses } from "@/types/user";
import ReceivedRequests from "@/components/Users/ReceivedRequests";

const DashboardReporters = ({ ...rest }) => {
  return (
    <Box {...rest}>
      <ReceivedRequests showDetails status={UserInvitationStatuses.WAITING} />
    </Box>
  );
};

export default DashboardReporters;
