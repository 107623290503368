/* eslint-disable react/display-name */
import Avatar from "@/components/common/Avatar";
import BoxCard from "@/components/common/BoxCard";
import DeleteDialog from "@/components/common/DeleteDialog";
import Table from "@/components/common/Table";
import { updateProfile } from "@/components/withAuthentication";
import { useToastPromise } from "@/hooks/useToast";
import { mutateSession } from "@/hooks/useUser";
import { useBreakpointValue } from "@chakra-ui/media-query";
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Link as StyledLink,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { format } from "date-fns";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useMemo, useState } from "react";
import { Edit, Trash2 } from "react-feather";
import useSWR, { mutate } from "swr";

const Events = ({ list = false }): React.ReactElement => {
  const { t } = useTranslation("common");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedId, setSelectedId] = useState();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const router = useRouter();
  const toast = useToastPromise();
  const { data = [], mutate } = useSWR(
    list ? "/event/office?active=true" : "/event/office"
  );

  const onDelete = () => {
    onClose();
    return toast.promise(
      axios.delete(`/event/${selectedId}`).then(async () => {
        await mutate();
      }),
      {
        success: t("events.eventDeleted"),
      }
    );
  };

  const selectEvent = useCallback(async (event) => {
    await updateProfile({ event: event.id ?? null });
    mutateSession();
    router.push("/events/edit");
  }, []);

  const outdatedEventsData = useMemo(
    () =>
      data
        .filter((event) => new Date(event.endDate) < new Date())
        .sort(
          (a, b) =>
            new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
        ),
    [data]
  );

  const currentEventsData = useMemo(
    () =>
      data
        .filter((event) => new Date(event.endDate) >= new Date())
        .sort(
          (a, b) =>
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        ),
    [data]
  );

  const columns = useMemo(
    () =>
      list
        ? []
        : [
            {
              Header: t("name"),
              id: "name",
              accessor: "name",
              Cell: ({ row }) => (
                <Flex align="center">
                  <Avatar
                    src={
                      row.original.picture
                        ? `/uploads/${row.original.picture}`
                        : null
                    }
                    size="md"
                    mr={4}
                    name={row.original.name}
                  />
                  <Text fontWeight="500">{row.original.name}</Text>
                </Flex>
              ),
            },
            {
              Header: t("events.eventStart"),
              id: "startDate",
              accessor: "startDate",
              Cell: ({ value }) => (
                <Text>{format(new Date(value), "dd/MM/yyyy")}</Text>
              ),
            },
            {
              Header: t("settings.end"),
              id: "endDate",
              accessor: "endDate",
              Cell: ({ value }) => (
                <Text>{format(new Date(value), "dd/MM/yyyy")}</Text>
              ),
            },
            {
              id: "edit",
              disableSortBy: true,
              accessor: "id",
              Cell: ({ value }) => (
                <Flex width="100%" justifyContent="flex-end" fontSize="20px">
                  <Tooltip hasArrow label={t("edit")} placement="top">
                    <StyledLink lineHeight="1" p="1">
                      <Icon
                        as={Edit}
                        onClick={(e) => {
                          e.stopPropagation();
                          router.push({
                            pathname: "/events/event/[id]",
                            query: { id: value },
                          });
                        }}
                      />
                    </StyledLink>
                  </Tooltip>
                  <Tooltip hasArrow label={t("delete")} placement="top">
                    <StyledLink
                      lineHeight="1"
                      p="1"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedId(value);
                        onOpen();
                      }}
                    >
                      <Icon as={Trash2} />
                    </StyledLink>
                  </Tooltip>
                </Flex>
              ),
            },
          ],
    [list]
  );

  return (
    <>
      <DeleteDialog
        isOpen={isOpen}
        onClose={onClose}
        onDelete={onDelete}
        name={t("deleteConfirm.event")}
      />
      {!list && (
        <Flex
          justify="space-between"
          alignItems="flex-start"
          direction={{ base: "column", md: "row" }}
        >
          <Heading as="h2" size="lg">
            {t("events.events")}
          </Heading>
          <Link href="/events/add">
            <Button mb={5}>{t("events.addEvent")}</Button>
          </Link>
        </Flex>
      )}
      <BoxCard
        p={0}
        w="100%"
        bg={{ base: "transparent", md: "white" }}
        boxShadow={{ base: "none", md: "0 3px 20px rgba(0,0,0,0.04)" }}
      >
        {list ? (
          <Box mt={3}>
            {data?.map((event) => (
              <Flex
                align="center"
                cursor="pointer"
                key={event.id}
                onClick={() => selectEvent(event)}
                mt={2}
              >
                <Avatar
                  name={event.name}
                  size="md"
                  mr={3}
                  src={`/uploads/${event?.picture}`}
                />
                <Box>
                  <Flex>
                    <Text fontWeight="500">{event.name}</Text>
                  </Flex>
                  <Text color="text.500" fontSize="xs">
                    {event.locations
                      .map((location) => location.name)
                      .join(", ")}{" "}
                    ({format(new Date(event.startDate), "dd/MM/yyyy")}
                    &nbsp;-&nbsp;
                    {format(new Date(event.endDate), "dd/MM/yyyy")})
                  </Text>
                </Box>
              </Flex>
            ))}
          </Box>
        ) : (
          <>
            <Tabs
              variant={useBreakpointValue({ base: "buttons", md: "solid" })}
              isLazy
              defaultIndex={0}
              onChange={(index) => setSelectedTabIndex(index)}
              index={selectedTabIndex}
            >
              <TabList>
                <Tab>{t("events.current")}</Tab>
                <Tab>{t("events.ended")}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Heading as="h2" size="lg">
                    {t("events.current")}
                  </Heading>
                  <BoxCard d={{ base: "none", md: "block" }}>
                    <Table
                      columns={columns}
                      data={currentEventsData || []}
                      onRowClick={(row) => selectEvent(row.original)}
                    />
                  </BoxCard>
                </TabPanel>
                <TabPanel>
                  <Heading as="h2" size="lg">
                    {t("events.ended")}
                  </Heading>
                  <BoxCard d={{ base: "none", md: "block" }}>
                    <Table
                      columns={columns}
                      data={outdatedEventsData || []}
                      onRowClick={(row) => selectEvent(row.original)}
                    />
                  </BoxCard>
                </TabPanel>
              </TabPanels>

              <SimpleGrid
                columns={1}
                spacing={3}
                d={{ base: "grid", md: "none" }}
              >
                {data?.map((event) => (
                  <BoxCard p={0} key={event?.id}>
                    <Flex
                      align="center"
                      justify="space-between"
                      p="1.25rem"
                      borderBottomColor="light.50"
                      borderBottomWidth="1px"
                    >
                      <Flex align="center">
                        <Avatar
                          name={event.name}
                          size="md"
                          mr={3}
                          src={`/uploads/${event?.picture}`}
                        />
                        <Box>
                          <Text fontWeight="500">{event.name}</Text>
                          <Text color="text.500" fontSize="xs">
                            {format(new Date(event.startDate), "dd/MM/yyyy")}
                            &nbsp;-&nbsp;
                            {format(new Date(event.endDate), "dd/MM/yyyy")}
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                    <Flex p="1.25rem" justifyContent="flex-end" fontSize="20px">
                      <Tooltip hasArrow label={t("edit")} placement="top">
                        <StyledLink lineHeight="1" p="1">
                          <Icon
                            as={Edit}
                            onClick={(e) => {
                              e.stopPropagation();
                              router.push({
                                pathname: "/events/event/[id]",
                                query: { id: event.id },
                              });
                            }}
                          />
                        </StyledLink>
                      </Tooltip>
                      <Tooltip hasArrow label={t("delete")} placement="top">
                        <StyledLink
                          lineHeight="1"
                          p="1"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedId(event.id);
                            onOpen();
                          }}
                        >
                          <Icon as={Trash2} />
                        </StyledLink>
                      </Tooltip>
                    </Flex>
                  </BoxCard>
                ))}
              </SimpleGrid>
            </Tabs>
          </>
        )}
      </BoxCard>
    </>
  );
};

export default Events;
