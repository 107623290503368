import React, { useMemo } from "react";
import { Avatar, Flex, Text, Box, Heading, SimpleGrid } from "@chakra-ui/react";
import BoxCard from "@/components/common/BoxCard";
import { useTranslation } from "next-i18next";
import { OfficeType } from "@/types/office";
import { useUser } from "@/hooks/useUser";
import useSWR from "swr";
import FlagImage from "@/components/common/FlagImage";

const routes = {
  [OfficeType.PRESS]: "/press-office",
  [OfficeType.EDITORIAL]: "/editorial-office",
};

enum userRole {
  ADMIN = "admin",
  MODERATOR = "mod",
}

const DashboardTeam = ({ ...rest }): React.ReactElement => {
  const { t } = useTranslation("common");
  const [session] = useUser();

  const type = session?.currentRole?.type;
  const currentOffice = session?.user?.currentOffice;
  const { data: users, mutate } = useSWR(`${routes[type]}/users`);

  const filteredUser = useMemo(
    () =>
      (users || [])?.filter(
        ({ user, role }) =>
          !!user && (role === userRole.ADMIN || role === userRole.MODERATOR)
      ),
    [users]
  );

  React.useEffect(() => {
    mutate();
  }, [currentOffice?.id]);

  return (
    <>
      {users?.length > 1 && (
        <Box {...rest}>
          <Heading as="h3" size="lg">
            {t("team")}
          </Heading>
          <SimpleGrid spacingY={2}>
            {filteredUser.map((user, index) => (
              <BoxCard p="1rem">
                <Flex align={"center"} key={`teamMember-${index}`}>
                  <Avatar
                    size="md"
                    src={`/uploads/${user.user?.profile?.picture}`}
                    name={`${user.user?.firstName} ${user.user?.lastName}`}
                    mr={4}
                  />
                  <Box>
                    <Flex align={"center"}>
                      <Text fontWeight="500">{`${user.user?.firstName} ${user.user?.lastName}`}</Text>
                      <FlagImage
                        ml={2}
                        language={user?.user?.profile?.locale}
                      />
                    </Flex>
                    <Text
                      fontSize="xs"
                      color="text.500"
                      lineHeight="1rem"
                      mt=".125rem"
                    >
                      {`${t(user.role)}`}
                    </Text>
                  </Box>
                  {
                    /* <Badge variant="green" ml="auto">
                  Status
                </Badge> */
                    //TODO add activity status when websockets will be ready
                  }
                </Flex>
              </BoxCard>
            ))}
          </SimpleGrid>
        </Box>
      )}
      {/* //TODO add info when there is no data */}
    </>
  );
};

export default DashboardTeam;
