import React from "react";
import BoxCard from "@/components/common/BoxCard";
import Link from "@/components/common/Link";
import SelectAdvanced from "@/components/common/SelectAdvanced";
import Applications from "@/components/Events/Applications/Applications";
import useIsMobile from "@/hooks/useIsMobile";
import { useUser } from "@/hooks/useUser";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Heading,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useTranslation } from "next-i18next";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Square } from "react-feather";
import useSWR from "swr";

const DashboardApplications = ({ ...rest }) => {
  const { t } = useTranslation("common");
  const [session] = useUser();
  const currentOffice = session?.user?.currentOffice;

  const { data: events, error } = useSWR(
    currentOffice?.id ? `/event/office/${currentOffice.id}?active=true` : null
  );

  const isLoading = !events && !error;
  const isMobile = useIsMobile();
  const [selectedEventId, setSelectedEventId] = useState(null);
  const selectedEvent = useMemo(
    () => events?.find((event) => event?.id === selectedEventId),
    [selectedEventId]
  );

  useEffect(() => {
    if (events) setSelectedEventId(events[0]?.id);
  }, [events]);

  const eventsOptions = useMemo(
    () =>
      events?.map((event) => ({
        value: event?.id,
        label: (
          <>
            <Text size="sm" d="flex" alignItems="center">
              <Avatar
                src={event.picture ? `/uploads/${event.picture}` : null}
                size="xs"
                mr={2}
                name={event.name}
              />
              {event.name}
            </Text>
          </>
        ),
      })),
    [events]
  );

  return (
    <Box {...rest}>
      <Heading as="h3" size="lg">
        {t("events.accreditationApplications")}
      </Heading>
      {isMobile && (
        <Box mb={4} mt={-4}>
          <SelectAdvanced
            options={eventsOptions}
            onChange={(selectedValue) =>
              setSelectedEventId(selectedValue?.value)
            }
            value={eventsOptions?.find((c) => selectedEventId === c?.value)}
            placeholder={t("events.chooseEvent")}
          />
        </Box>
      )}
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {!isLoading && events?.length > 0 && (
        <VStack spacing={5}>
          {isMobile ? (
            <BoxCard
              key={`event-${selectedEvent?.id}`}
              w="100%"
              p={{ base: 0, md: "1.25rem" }}
              bg={{ base: "transparent", md: "white" }}
              boxShadow={{
                base: "none",
                md: "0 3px 20px rgba(0,0,0,0.04)",
              }}
            >
              <Fragment>
                <Heading as="h4" size="md" mb={3} d="flex" alignItems="center">
                  {selectedEvent?.name && (
                    <Avatar
                      src={
                        selectedEvent?.picture
                          ? `/uploads/${selectedEvent?.picture}`
                          : null
                      }
                      size="md"
                      mr={4}
                      name={selectedEvent?.name}
                    />
                  )}
                  {selectedEvent?.name}
                </Heading>
                <Applications list eventId={selectedEvent?.id} />
              </Fragment>
            </BoxCard>
          ) : (
            <>
              {events?.map((event) => (
                <BoxCard
                  key={`event-${event.id}`}
                  w="100%"
                  p={{ base: 0, md: "1.25rem" }}
                  bg={{ base: "transparent", md: "white" }}
                  boxShadow={{
                    base: "none",
                    md: "0 3px 20px rgba(0,0,0,0.04)",
                  }}
                >
                  <Fragment>
                    <Heading
                      as="h4"
                      size="md"
                      mb={3}
                      d="flex"
                      alignItems="center"
                    >
                      <Avatar
                        src={event.picture ? `/uploads/${event.picture}` : null}
                        size="md"
                        mr={4}
                        name={event.name}
                      />
                      {event.name}
                      {event.accreditationActive === false &&
                        event.accreditationFrom &&
                        new Date() < new Date(event.accreditationTo) && (
                          <Badge ml="auto" variant="subtle">
                            {t("events.accreditationFrom", {
                              date: format(
                                new Date(event.accreditationFrom),
                                "dd/MM/yyyy HH:mm"
                              ),
                            })}
                          </Badge>
                        )}
                      {event.accreditationActive === false &&
                        event.accreditationTo &&
                        new Date() > new Date(event.accreditationTo) && (
                          <Badge ml="auto" variant="subtle">
                            {t("events.accreditationEnded")}
                          </Badge>
                        )}
                      {event.accreditationActive === false &&
                        !event.accreditationTo && (
                          <Badge ml="auto" variant="subtle">
                            {t("events.accreditationNotStarted")}
                          </Badge>
                        )}
                    </Heading>
                    <Applications list eventId={event.id} />
                  </Fragment>
                </BoxCard>
              ))}
            </>
          )}
        </VStack>
      )}
      {!isLoading && events?.length === 0 && (
        <BoxCard p={3} textAlign="center">
          <Text fontWeight="500">{t("events.noEvents")}</Text>
          <Text px={2} fontSize="1.125rem" mt="1.25rem" opacity="0.7">
            {t("events.noEventsApplicationsSubtitle")}
          </Text>
          <Link to="/events/add">
            <Button leftIcon={<Square />} variant="solid" size="md" mt={5}>
              {t("events.addEvent")}
            </Button>
          </Link>
        </BoxCard>
      )}
    </Box>
  );
};

export default DashboardApplications;
