import React from "react";
import { Heading, Box } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import Calendar from "@/components/Calendar";

const DashboardCalendar = ({ ...rest }) => {
  const { t } = useTranslation("common");

  return (
    <Box {...rest} w="100%" maxW={{ base: "calc(100vw - 56px)", md: "100%" }}>
      <Heading as="h3" size="lg">
        {t("calendar.calendar")}
      </Heading>
      <div className="dashboard">
        <Calendar small />
      </div>
    </Box>
  );
};

export default DashboardCalendar;
