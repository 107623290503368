import BoxCard from "@/components/common/BoxCard";
import Link from "@/components/common/Link";
import Excerpt from "@/components/News/Excerpt";
import { usePressOrganization, usePressUser } from "@/hooks/usePress";
import { useUser } from "@/hooks/useUser";
import {
  Box,
  Button,
  Center,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import { FileText } from "react-feather";

const DashboardNews = ({ organization = false, ...rest }) => {
  const { t } = useTranslation("common");
  const [session] = useUser();

  const { isLoading, articles: userArticlesData } = usePressUser();
  const {
    isLoading: isOrganizationArticlesLoading,
    articles: organizationArticlesData,
  } = usePressOrganization(session?.currentRole?.id);

  const articles = useMemo(
    () =>
      (!organization ? userArticlesData : organizationArticlesData || []).slice(
        0,
        3
      ),
    [userArticlesData, organizationArticlesData, organization]
  );

  return (
    <Box {...rest}>
      <Heading as="h3" size="lg">
        {t("press.news")}
      </Heading>
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {((!organization && !isLoading && userArticlesData?.length > 0) ||
        (organization &&
          !isOrganizationArticlesLoading &&
          organizationArticlesData?.length > 0)) && (
        <SimpleGrid columns={1} spacing={6}>
          {articles?.map((article) => (
            <Excerpt key={`article-${article.id}`} article={article} />
          ))}
        </SimpleGrid>
      )}
      {(!organization && !isLoading && userArticlesData?.length == 0) ||
        (organization &&
          !isOrganizationArticlesLoading &&
          organizationArticlesData?.length == 0 && (
            <BoxCard p={3} textAlign="center">
              <Text fontWeight="500">{t("press.noArticles")}</Text>
              <Text px={2} fontSize="1.125rem" mt="1.25rem" opacity="0.7">
                {t(
                  `press.${
                    organization
                      ? "noArticlesSubtitleOrganization"
                      : "noArticlesSubtitle"
                  }`
                )}
              </Text>
              <Link to="/offices/all">
                <Button
                  leftIcon={<FileText />}
                  variant="solid"
                  size="md"
                  mt={5}
                >
                  {t("pressOffice.pressOffices")}
                </Button>
              </Link>
            </BoxCard>
          ))}
    </Box>
  );
};

export default DashboardNews;
