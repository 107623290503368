import React, { useState, useCallback } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  VStack,
  StackProps,
} from "@chakra-ui/react";
import axios from "axios";
import { useTranslation } from "next-i18next";

import { mutateSession, useUser } from "@/hooks/useUser";
import Link from "@/components/common/Link";

const ReporterAlerts = ({ ...rest }: StackProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const [hide, setHide] = useState({
    hideSocialMediaAlert: false,
    hidePublicationsAlert: false,
  });
  const [session] = useUser();

  const showPublicationsAlert = session?.user?.profile?.showPublicationsAlert;
  const showSocialMediaAlert = session?.user?.profile?.showSocialMediaAlert;
  const publications = session?.user?.profile?.publications;

  const userSocialMedia =
    session?.user?.profile?.facebook &&
    session?.user?.profile?.instagram &&
    session?.user?.profile?.linkedin &&
    session?.user?.profile?.tiktok &&
    session?.user?.profile?.twitch &&
    session?.user?.profile?.twitter &&
    session?.user?.profile?.youtube &&
    session?.user?.profile?.otherSocial;

  const onCloseAlert = useCallback(
    (alertToHide: string) => {
      setHide({
        ...hide,
        [alertToHide]: true,
      });
      return axios
        .patch("/profile/alerts", { [alertToHide]: true })
        .then(() => {
          mutateSession();
        });
    },
    [hide]
  );

  if (
    (!showSocialMediaAlert || hide.hideSocialMediaAlert || userSocialMedia) &&
    (!showPublicationsAlert || hide.hidePublicationsAlert || publications)
  )
    return null;

  return (
    <VStack spacing={2} mb={2} {...rest}>
      {showSocialMediaAlert && !hide.hideSocialMediaAlert && !userSocialMedia && (
        <Alert status="warning">
          <AlertIcon />
          <Link to="/profile/tab/social" color="white">
            <AlertTitle>{t("press.socialMedia")}</AlertTitle>
            <AlertDescription fontWeight={"light"}>
              {t("users.socialMediaDashboardAlert")}
            </AlertDescription>
          </Link>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => onCloseAlert("hideSocialMediaAlert")}
          />
        </Alert>
      )}

      {showPublicationsAlert && !hide.hidePublicationsAlert && !publications && (
        <Alert status="warning">
          <AlertIcon />
          <Link to="/profile/publications" color="white">
            <AlertTitle>{t("users.publications")}</AlertTitle>
            <AlertDescription fontWeight={"light"}>
              {t("users.publicationsDashboardAlert")}
            </AlertDescription>
          </Link>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => onCloseAlert("hidePublicationsAlert")}
          />
        </Alert>
      )}
    </VStack>
  );
};

export default ReporterAlerts;
