/* eslint-disable react/react-in-jsx-scope */
import BoxCard from "@/components/common/BoxCard";
import {
  Box,
  Icon,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
  BoxProps,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useTranslation } from "next-i18next";
import { Database } from "react-feather";
import useSWR from "swr";

const DashboardMaintenance = ({ ...rest }: BoxProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const { data: maintenance } = useSWR("/maintenance");
  const isLoading = false;

  return (
    <Box {...rest} mt={5}>
      <Heading as="h3" size="lg">
        {t("payments.subscription.maintenance")}
      </Heading>
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {!isLoading && (
        <BoxCard textAlign="center">
          <Icon as={Database} color="main.800" w={20} h={20} mb={2} />
          <Text fontWeight={700}>
            {t("payments.subscription.maintenanceDatePeriod")}
          </Text>
          <Text fontWeight="600" fontSize="xl" mt={1}>
            {format(new Date(maintenance?.start), "dd/MM/yyy")} -{" "}
            {format(new Date(maintenance?.end), "dd/MM/yyy")}
          </Text>
        </BoxCard>
      )}
    </Box>
  );
};

export default DashboardMaintenance;
