/* eslint-disable react/react-in-jsx-scope */
import BoxCard from "@/components/common/BoxCard";
import Link from "@/components/common/Link";
import SubscriptionIcon from "@/components/common/SubscriptionIcon";
import TransComponent from "@/components/common/Trans";
import { useUser } from "@/hooks/useUser";
import { OfficeType } from "@/types/office";
import {
  Maintenance,
  Subscription,
  SubscriptionPackageName,
} from "@/types/subscription";
import {
  Box,
  BoxProps,
  Button,
  Center,
  Grid as ChakraGrid,
  Icon as ChakraIcon,
  Flex,
  Heading,
  Progress,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { filesize } from "filesize";
import { useTranslation } from "next-i18next";
import React from "react";
import {
  CheckCircle,
  DollarSign,
  Grid,
  Mail,
  MinusCircle,
  Send,
  UploadCloud,
  Users,
} from "react-feather";
import useSWRImmutable from "swr/immutable";

import { subscriptionIcon } from "../Payments/SubscriptionCard";
import DashboardMaintenance from "./Maintenance";

const DashboardSubscription = ({ ...rest }: BoxProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const [session] = useUser();

  const { data: subscription, isValidating: subscriptionIsValidating } =
    useSWRImmutable<Subscription>("/subscription");
  const { data: maintenance, isValidating: maintenanceIsValidating } =
    useSWRImmutable<Maintenance>("/maintenance");
  const isLoading = subscriptionIsValidating || maintenanceIsValidating;

  const { data: deleteDate } = useSWRImmutable(
    !isLoading ? `/subscription/delete-date` : null
  );

  const { type } = session?.currentRole || {};

  // eslint-disable-next-line react/display-name
  const CustomProgress = React.forwardRef((props, ref) => (
    <Progress ref={ref} {...props}></Progress>
  ));

  const CustomBox = React.forwardRef(({ children, ...props }, ref) => {
    return (
      <Box ref={ref} {...props}>
        {children}
      </Box>
    );
  });

  return (
    <>
      <Box {...rest} mt={5}>
        <Heading as="h3" size="lg">
          {t("payments.subscription.subscription")}
        </Heading>
        {isLoading && (
          <Center>
            <Spinner />
          </Center>
        )}
        {!isLoading && subscription && (
          <BoxCard>
            <Flex
              direction={{
                base: "column",
                xl: type === OfficeType.ORGANIZATION ? "column" : "row",
              }}
              alignItems="center"
              justifyContent="space-around"
            >
              <Flex
                pr={{
                  base: 0,
                  xl: type === OfficeType.ORGANIZATION ? 0 : "1.25rem",
                }}
                align="center"
                w={{
                  base: "100%",
                  xl: type === OfficeType.ORGANIZATION ? "100%" : "40%",
                }}
              >
                <Box textAlign="center" w="100%">
                  {(subscriptionIcon[subscription?.subscriptionPackage?.name] ||
                    subscription?.organizationId) && (
                    <SubscriptionIcon
                      packageIcon={
                        subscriptionIcon[
                          subscription?.subscriptionPackage?.name
                        ] && !subscription?.organizationId
                          ? subscriptionIcon[
                              subscription?.subscriptionPackage?.name
                            ]
                          : subscriptionIcon.ENTERPRISE
                      }
                      packageName={
                        subscription?.subscriptionPackage?.name &&
                        !subscription?.organizationId
                          ? subscription?.subscriptionPackage?.name?.toUpperCase()
                          : subscription?.organizationId
                          ? "ENTERPRISE"
                          : null
                      }
                    />
                  )}
                  <Box mb={5}>
                    {subscription?.subscriptionPackage?.name &&
                      !subscription?.organizationId && (
                        <Heading as="h5" size="lg" mb={0}>
                          {subscription?.subscriptionPackage?.name?.toUpperCase()}
                        </Heading>
                      )}
                    {subscription?.organizationId && (
                      <Heading as="h5" size="lg" mb={0}>
                        {SubscriptionPackageName.ENTERPRISE}
                      </Heading>
                    )}
                    {subscription?.organizationId &&
                      subscription?.pressOfficeId && (
                        <Text
                          color="text.500"
                          fontSize="xs"
                          lineHeight={1.2}
                          mt={1}
                        >
                          {t("payments.subscriptionFromOrganization")}
                        </Text>
                      )}
                  </Box>
                  {subscription?.end && !subscription?.organizationId && (
                    <>
                      <Text fontWeight={700}>
                        {t("payments.subscription.endDate")}
                      </Text>
                      <Text fontWeight="600" fontSize="xl" mt={1}>
                        {format(new Date(subscription?.end), "dd/MM/yyy")}
                      </Text>
                    </>
                  )}
                  <Box mt={5} color="text.700">
                    <Flex
                      align="center"
                      textAlign="left"
                      lineHeight={1.2}
                      mt={3}
                    >
                      <ChakraIcon as={Users} w="15px" h="15px" mr={2} />
                      <TransComponent
                        i18nKey="payments.subscription.adminLimit"
                        value={subscription?.adminAccountsLimit}
                        count={subscription?.adminAccountsLimit}
                      />
                    </Flex>
                    <Flex
                      align="center"
                      textAlign="left"
                      lineHeight={1.2}
                      mt={3}
                    >
                      <ChakraIcon as={Users} w="15px" h="15px" mr={2} />
                      <TransComponent
                        i18nKey="payments.subscription.moderatorLimit"
                        value={subscription?.modAccountsLimit}
                        count={subscription?.modAccountsLimit}
                      />
                    </Flex>
                    <Flex
                      align="center"
                      textAlign="left"
                      lineHeight={1.2}
                      mt={3}
                    >
                      <ChakraIcon as={Send} w="15px" h="15px" mr={2} />
                      <TransComponent
                        i18nKey="payments.subscription.smsLimit"
                        value={subscription?.smsLimit}
                      />
                    </Flex>
                    <Flex
                      align="center"
                      textAlign="left"
                      lineHeight={1.2}
                      mt={3}
                    >
                      <ChakraIcon as={Mail} w="15px" h="15px" mr={2} />
                      <TransComponent
                        i18nKey="payments.subscription.mailLimit"
                        value={subscription?.mailLimit}
                      />
                    </Flex>
                    <Flex
                      align="center"
                      textAlign="left"
                      lineHeight={1.2}
                      mt={3}
                    >
                      <ChakraIcon as={UploadCloud} w="15px" h="15px" mr={2} />
                      <TransComponent
                        i18nKey="payments.subscription.cloudLimit"
                        value={filesize(subscription?.cloudSpaceLimit, {
                          base: 2,
                          standard: "jedec",
                        })}
                      />
                    </Flex>
                    <Flex
                      align="center"
                      textAlign="left"
                      lineHeight={1.2}
                      mt={3}
                    >
                      <ChakraIcon as={Grid} w="15px" h="15px" mr={2} />
                      <TransComponent
                        i18nKey="payments.subscription.eventsLimit"
                        value={subscription?.activeEventsLimit}
                      />
                    </Flex>
                    <Flex
                      align="center"
                      textAlign="left"
                      lineHeight={1.2}
                      mt={3}
                    >
                      {subscription?.pressWebsiteAccess ? (
                        <ChakraIcon
                          as={CheckCircle}
                          w="15px"
                          h="15px"
                          mr={2}
                          flexShrink={0}
                        />
                      ) : (
                        <ChakraIcon
                          as={MinusCircle}
                          w="15px"
                          h="15px"
                          mr={2}
                          flexShrink={0}
                        />
                      )}
                      <Text>{t("payments.subscription.pageService")}</Text>
                    </Flex>
                  </Box>
                </Box>
              </Flex>
              <Flex direction="column">
                <Text mb={5} fontWeight={500} textAlign="center" mt="20px">
                  {t("payments.subscription.actual", {
                    start: format(
                      new Date(subscription?.billingDate?.start),
                      "dd/MM/yyy"
                    ),
                    end: format(
                      new Date(subscription?.billingDate?.end),
                      "dd/MM/yyy"
                    ),
                  })}
                </Text>
                <ChakraGrid templateColumns="100px 1fr" alignItems="center">
                  <Text>{t("payments.subscription.sms")}</Text>
                  <Tooltip
                    label={t("payments.subscription.usageTooltip", {
                      used: subscription?.usage?.sms,
                      rest: subscription?.smsLimit - subscription?.usage?.sms,
                    })}
                  >
                    <CustomBox>
                      <CustomProgress
                        height="32px"
                        value={
                          (subscription?.usage?.sms / subscription?.smsLimit) *
                          100
                        }
                      />
                    </CustomBox>
                  </Tooltip>
                </ChakraGrid>
                <ChakraGrid
                  templateColumns="100px 1fr"
                  mt="10px"
                  alignItems="center"
                >
                  <Text>{t("payments.subscription.mails")}</Text>
                  <Tooltip
                    label={t("payments.subscription.usageTooltip", {
                      used: subscription?.usage?.emails,
                      rest:
                        subscription?.mailLimit - subscription?.usage?.emails,
                    })}
                  >
                    <CustomBox>
                      <CustomProgress
                        height="32px"
                        value={
                          (subscription?.usage?.emails /
                            subscription?.mailLimit) *
                          100
                        }
                      />
                    </CustomBox>
                  </Tooltip>
                </ChakraGrid>
                <ChakraGrid
                  templateColumns="100px 1fr"
                  mt="10px"
                  alignItems="center"
                >
                  <Text>{t("payments.subscription.activeEvents")}</Text>
                  <Tooltip
                    label={t("payments.subscription.usageTooltip", {
                      used: subscription?.usage?.events,
                      rest:
                        subscription?.activeEventsLimit -
                        subscription?.usage?.events,
                    })}
                  >
                    <CustomBox>
                      <CustomProgress
                        height="32px"
                        value={
                          (subscription?.usage?.events /
                            subscription?.activeEventsLimit) *
                          100
                        }
                      />
                    </CustomBox>
                  </Tooltip>
                </ChakraGrid>
                <ChakraGrid
                  templateColumns="100px 1fr"
                  mt="10px"
                  alignItems="center"
                >
                  <Text>{t("payments.subscription.cloudSpace")}</Text>
                  <Tooltip
                    label={t("payments.subscription.usageTooltip", {
                      used: filesize(subscription?.usage?.cloudSpace, {
                        base: 2,
                        standard: "jedec",
                      }),
                      rest: filesize(
                        subscription?.cloudSpaceLimit -
                          subscription?.usage?.cloudSpace,
                        {
                          base: 2,
                          standard: "jedec",
                        }
                      ),
                    })}
                  >
                    <CustomBox>
                      <CustomProgress
                        height="32px"
                        value={
                          (subscription?.usage?.cloudSpace /
                            subscription?.cloudSpaceLimit) *
                          100
                        }
                      />
                    </CustomBox>
                  </Tooltip>
                </ChakraGrid>
              </Flex>
            </Flex>
          </BoxCard>
        )}
        {!isLoading && !subscription && (
          <BoxCard p={3} textAlign="center">
            <Text fontWeight="500">
              {t("payments.subscription.noSubscription")}
            </Text>
            {deleteDate?.deleteOfficeDataDate && (
              <Text px={2} fontSize="1.125rem" mt="1.25rem" fontWeight="500">
                {t("payments.subscription.noSubscriptionDeleteDate", {
                  date: format(
                    new Date(deleteDate?.deleteOfficeDataDate),
                    "dd/MM/yyy"
                  ),
                })}
              </Text>
            )}
            <Text px={2} fontSize="1.125rem" mt="1.25rem" opacity="0.7">
              {t(
                `payments.subscription.${
                  maintenance
                    ? "noSubscriptionSubtitleMaintenance"
                    : "noSubscriptionSubtitle"
                }`
              )}
            </Text>
            <Link to="/payments">
              <Button
                leftIcon={<DollarSign />}
                variant="solid"
                size="md"
                mt={5}
              >
                {t("payments.goToPayments")}
              </Button>
            </Link>
          </BoxCard>
        )}
      </Box>

      {maintenance &&
        ((type == "press" &&
          maintenance?.pressOfficeId == session?.currentRole?.id) ||
          (type == "organization" &&
            maintenance?.organizationId == session?.currentRole?.id)) && (
          <DashboardMaintenance />
        )}
    </>
  );
};

export default DashboardSubscription;
