import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useTranslation } from "next-i18next";
import React, { useMemo, useState } from "react";
import useSWR, { mutate } from "swr";

import User from "@/components/common/UserOfficeBadge";
import { useToastPromise } from "@/hooks/useToast";
import BoxCard from "../common/BoxCard";
import Table from "../common/Table";

const DashboardLatestOffices = ({ ...rest }: BoxProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const toast = useToastPromise();
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: officesData,
    error,
    mutate: mutateOffices,
  } = useSWR("/press-office?latest=true");

  const update = () => {
    mutate("/press-office/subscribed");
    mutate("/user/press-office");
    mutateOffices();
  };

  const onSubscribeClick = (id, subscribed) => {
    setIsLoading(true);
    return toast.promise(
      axios
        .post(`/press-office/${id}/subscription`, {
          status: subscribed ? "unsubscribe" : "subscribe",
        })
        .then(() => mutateOffices())
        .finally(() => setIsLoading(false))
    );
  };

  const officesColumns = useMemo(
    () => [
      {
        Header: t("pressOffice.pressOffice"),
        accessor: (pressOffice) => pressOffice?.name,
        Cell: function Cell({ row }) {
          return (
            <User
              type="press"
              name={row?.original?.name}
              id={row?.original?.id}
              picture={row?.original?.picture}
              website={row?.original?.url}
              url={row?.original?.url}
              subscribed={!!row?.original?.subscribers?.length}
              onUpdate={update}
              showOfficeProfileIcon={false}
            />
          );
        },
      },
      {
        disableSortBy: true,
        id: "event",
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => {
          return (
            <Flex width="100%" justifyContent="flex-end">
              <Button
                onClick={() =>
                  onSubscribeClick(
                    row?.original?.id,
                    !!row?.original?.subscribers?.length
                  )
                }
              >
                {!!row?.original?.subscribers?.length
                  ? t(`website.unsubscribe`)
                  : t(`website.subscribe`)}
              </Button>
            </Flex>
          );
        },
      },
    ],
    []
  );

  if (error)
    return (
      <Box
        w="100%"
        maxW={{ base: "calc(100vw - 56px)", md: "100%" }}
        zIndex={9}
        {...rest}
      >
        <Heading as="h3" size="lg">
          {t("pressOffice.latestOffices")}
        </Heading>
        <BoxCard
          p={0}
          w="100%"
          bg={{ base: "transparent", md: "white" }}
          boxShadow={{ base: "none", md: "0 3px 20px rgba(0,0,0,0.04)" }}
        >
          <Text>{t("pressOffice.latestOfficesError")}</Text>
        </BoxCard>
      </Box>
    );

  return (
    <>
      {officesData?.length > 0 ? (
        <Box
          w="100%"
          maxW={{ base: "calc(100vw - 56px)", md: "100%" }}
          zIndex={9}
          {...rest}
        >
          <Heading as="h3" size="lg">
            {t("pressOffice.latestOffices")}
          </Heading>
          <BoxCard
            p={0}
            w="100%"
            bg={{ base: "transparent", md: "white" }}
            boxShadow={{ base: "none", md: "0 3px 20px rgba(0,0,0,0.04)" }}
          >
            {(isLoading || !officesData) && (
              <Center>
                <Spinner />
              </Center>
            )}
            {!isLoading && (
              <Box d={{ base: "none", md: "block" }}>
                <Table
                  columns={officesColumns}
                  data={officesData || []}
                  searchBar={false}
                  pagination={false}
                />
              </Box>
            )}
          </BoxCard>
        </Box>
      ) : null}
    </>
  );
};

export default DashboardLatestOffices;
