export const sortColumsOfStrings = (
  rowA: any,
  rowB: any,
  columnId: string,
  desc: boolean
): boolean => {
  const defaultVal = desc
    ? "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
    : "ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ";
  return (rowA.values[columnId] ?? defaultVal).localeCompare(
    rowB.values[columnId] ?? defaultVal
  );
};
