import axios from "axios";
import useSWR, { mutate } from "swr";
import React, { useState } from "react";
import { matchSorter } from "match-sorter";
import {
  SimpleGrid,
  Heading,
  Text,
  useDisclosure,
  Button,
  Flex,
} from "@chakra-ui/react";
import Link from "@/components/common/Link";
import { useTranslation } from "next-i18next";

import Card from "./Card";
import ResponseModal from "./ResponseModal";
import { useToastPromise } from "@/hooks/useToast";
import { UserInvitationStatuses } from "@/types/user";
import { SocialMedia } from "@/types/socialMedia";
import BoxCard from "@/components/common/BoxCard";
import { Users } from "react-feather";
import { useUser } from "@/hooks/useUser";
import HelpTooltip from "@/components/common/HelpTooltip";

type Props = {
  showDetails?: true;
  searchValue?: string;
  status?: UserInvitationStatuses;
};

const ReceivedRequests = ({
  showDetails,
  searchValue,
  status,
}: Props): React.ReactElement => {
  const { t } = useTranslation("common");
  const toast = useToastPromise();
  const [session] = useUser();
  const [selectedId, setSelectedId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, mutate: mutateRequests } = useSWR(
    `/office-request/office-requests/${status ?? ""}`
  );
  const requests = React.useMemo(() => {
    if (!searchValue) return data;
    return searchValue.split(" ").reduceRight(
      (results, term) =>
        matchSorter(results, term, {
          keys: ["user.firstName", "user.lastName"],
        }),
      data
    );
  }, [data, searchValue]);

  const currentOffice = session?.user?.currentOffice;
  React.useEffect(() => {
    mutateRequests();
  }, [currentOffice?.id]);

  const approveRequest = (id, status, response = undefined) => {
    return toast.promise(
      axios
        .put(`/office-request/office-request/${id}`, {
          status,
          response,
        })
        .then(() => {
          if (isOpen) onClose();
          mutateRequests();
          mutate(`/office-request/office-requests/${status ?? ""}`);
          mutate("/office-request/office-invitations");
          if (status === "accept") mutate("/editorial-office/users");
        })
    );
  };

  const handleApprove = (id, status) => {
    if (status === "accept") approveRequest(id, status);
    else {
      setSelectedId(id);
      onOpen();
    }
  };

  return (
    <>
      {isOpen && (
        <ResponseModal
          isOpen
          id={selectedId}
          onClose={onClose}
          onSave={approveRequest}
        />
      )}
      {showDetails && (
        <Heading as="h3" size="lg">
          <Flex align="center">
            {t("office.reporters")}
            <HelpTooltip label={t("users.reportersRequests")} />
          </Flex>
        </Heading>
      )}
      {data?.length > 0 || !showDetails ? (
        <SimpleGrid
          columns={{ base: 1, md: requests?.length > 0 ? 2 : 1 }}
          spacingX={3}
          spacingY={3}
          flex="1"
        >
          {requests?.length === 0 && (
            <Text textAlign="center" fontWeight="500" py={2}>
              {t(searchValue === "" ? "users.noUsers" : "noResultsFound")}
            </Text>
          )}
          {requests?.map(({ id, user, status, message, response }) => (
            <Card
              key={id}
              user={{
                id,
                status,
                message,
                response,
                firstName: user?.firstName,
                lastName: user?.lastName,
                picture: user?.profile?.picture,
                language: user?.profile?.locale,
                editorialOffices: user?.editorialOfficeUsers?.filter(
                  (officeUser) =>
                    officeUser?.editorialOffice?.activated === true
                ),
                officeRequestDate: user?.createdAt,
                socialMedia: [
                  {
                    name: SocialMedia.FACEBOOK,
                    nick: user?.profile?.facebook,
                  },
                  {
                    name: SocialMedia.INSTAGRAM,
                    nick: user?.profile?.instagram,
                  },
                  { name: SocialMedia.TWITCH, nick: user?.profile?.twitch },
                  { name: SocialMedia.TWITTER, nick: user?.profile?.twitter },
                  { name: SocialMedia.YOUTUBE, nick: user?.profile?.youtube },
                ],
              }}
              onApprove={handleApprove}
            />
          ))}
        </SimpleGrid>
      ) : (
        <BoxCard p={3} textAlign="center">
          <Text fontWeight="500">{t("editorialOffice.noRequests")}</Text>
          <Text px={2} fontSize="1.125rem" mt="1.25rem" opacity="0.7">
            {t("editorialOffice.noRequestsSubtitle")}
          </Text>
          <Link to="/users">
            <Button leftIcon={<Users />} variant="solid" size="md" mt={5}>
              {t("office.users")}
            </Button>
          </Link>
        </BoxCard>
      )}
    </>
  );
};

export default ReceivedRequests;
