import React, { useMemo } from "react";
import dynamic from "next/dynamic";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { LatLng } from "leaflet";
import { useTranslation } from "react-i18next";

const Map = ({
  width = "100%",
  height = "300px",
  isLoading = false,
  isError = false,
  rounded = true,
  ...props
}: {
  width?: string;
  height?: string;
  isLoading?: boolean;
  isError?: boolean;
  rounded?: boolean;
  centerMarker?: boolean;
  center?: LatLng | number[];
  onMove?: (center: LatLng) => void;
  [x: string]: any;
}): JSX.Element => {
  const Leaflet = useMemo(
    () =>
      dynamic(() => import("./Leaflet"), {
        loading: MapSpinner,
        ssr: false,
      }),
    []
  );
  const { t } = useTranslation();

  return (
    <Flex width={width} height={height} justifyContent="stretch">
      {!isLoading && !isError && <Leaflet rounded={rounded} {...props} />}
      {isLoading && <MapSpinner rounded={rounded} />}
      {isError && (
        <MapBox rounded={rounded}>
          <Text>{t("errorKeys.geolocationError")}</Text>
        </MapBox>
      )}
    </Flex>
  );
};

const MapBox = ({
  rounded,
  children,
}: {
  rounded: boolean;
  children: JSX.Element;
}): JSX.Element => (
  <Flex
    flex={1}
    alignItems="center"
    justifyContent="center"
    style={{
      borderRadius: rounded ? ".375rem" : 0,
      background: "#F0F0F0",
    }}
  >
    {children}
  </Flex>
);

const MapSpinner = ({ rounded }: { rounded: boolean }): JSX.Element => (
  <MapBox rounded={rounded}>
    <Spinner />
  </MapBox>
);

export default Map;
