import React from "react";
import { format } from "date-fns";
import { useTranslation } from "next-i18next";
import {
  Flex,
  Badge,
  Tooltip,
  Button,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  IconButton,
  Link as StyledLink,
  AvatarGroup,
  Checkbox,
} from "@chakra-ui/react";
import Link from "next/link";
import { LanguageShort } from "@/types/common";
import { prependURL } from "@/utils/helpers";
import Avatar from "@/components/common/Avatar";
import BoxCard from "@/components/common/BoxCard";
import { Image } from "@/components/common/Image";
import { Check, X } from "react-feather";
import { MoreVertical } from "react-feather";
import { UserInvitationStatuses } from "@/types/user";
import { getSocialMediaURL } from "@/utils/socialMedia";
import { SocialMedia as SocialMediaTypes } from "@/types/socialMedia";
import { useUser } from "@/hooks/useUser";
import FlagImage from "@/components/common/FlagImage";

type SocialMedia = {
  name: SocialMediaTypes;
  nick: string;
};

type EditorialOfficeUsers = {
  editorialOffice: {
    name: string;
    picture: string;
    url?: string;
  };
};

type Props = {
  user: {
    id?: number;
    email?: string;
    createdAt?: string;
    firstName?: string;
    lastName?: string;
    message?: string;
    response?: string;
    role?: string;
    roles?: string[];
    status?: UserInvitationStatuses;
    socialMedia?: SocialMedia[];
    picture?: string;
    language?: LanguageShort;
    editorialOffices?: EditorialOfficeUsers[];
    officeRequestDate?: string;
  };
  onEdit?: (id) => void;
  onDelete?: (id) => void;
  onApprove?: (id, status) => void;
  openMessageModal?: (id) => void;
  openInvitationModal?: (id) => void;
  onAddToSubscribersCategory?: (id) => void;
  onDeleteFromSubscribersCategory?: (id) => void;
  onSelectCard?: (id, e) => void;
  isSelected?: boolean;
};

const Card = ({
  user,
  onEdit,
  onDelete,
  onApprove,
  openMessageModal,
  openInvitationModal,
  onAddToSubscribersCategory,
  onDeleteFromSubscribersCategory,
  onSelectCard,
  isSelected,
}: Props): React.ReactElement => {
  const { t } = useTranslation("common");
  const roles = user.roles || [];
  const showSecondLine =
    roles.length > 0 ||
    onEdit ||
    onDelete ||
    (onApprove && user?.status === UserInvitationStatuses.WAITING) ||
    openMessageModal ||
    openInvitationModal;
  const [session] = useUser();

  return (
    <BoxCard p={0}>
      <Flex
        align="center"
        justify="space-between"
        p="1.25rem"
        borderBottomColor={showSecondLine ? `light.50` : undefined}
        borderBottomWidth={showSecondLine ? `1px` : undefined}
      >
        <Flex align="center">
          {onSelectCard && typeof isSelected === "boolean" && (
            <Checkbox
              size="sm"
              isChecked={isSelected}
              onChange={(e) => onSelectCard(user?.id, e.currentTarget.checked)}
              ml={-3}
              mt={-14}
            />
          )}
          <Avatar
            name={user.id ? `${user.firstName} ${user.lastName}` : ""}
            size="md"
            mr={3}
            src={`/uploads/${user?.picture}`}
            alignSelf="start"
          />
          <Box>
            <Flex mb={user.status ? 1 : 0} wrap="wrap">
              <Text fontWeight="500" mr={2}>
                {user?.id ? `${user.firstName} ${user.lastName}` : user.email}
              </Text>
              {user?.language && (
                <Box w="16px" h="16px" position="relative" mr={2} mt="2px">
                  <FlagImage language={user.language} />
                </Box>
              )}
              {user.status && (
                <Badge
                  fontSize="10px"
                  px="5.5px"
                  py="0"
                  variant={
                    user.status == UserInvitationStatuses.ACCEPTED
                      ? "green"
                      : user.status == UserInvitationStatuses.REJECTED
                      ? "red"
                      : "solid"
                  }
                >
                  {t(user.status)}
                </Badge>
              )}
            </Flex>
            <Text color="text.500" fontSize="xs">
              {user.officeRequestDate && (
                <Box>
                  {t("editorialOffice.officeRequestDate")}:{" "}
                  {format(new Date(user.officeRequestDate), "dd/MM/yyyy")}
                </Box>
              )}
              {user.message &&
                (user.response ? (
                  <>
                    <Box mb={1}>
                      <b>{t("office.requestMessage")}: </b>
                      <br />
                      {user.message}
                    </Box>
                    <Box>
                      <b>{t("office.responseMessage")}: </b>
                      <br />
                      {user.response}
                    </Box>
                  </>
                ) : (
                  user.message
                ))}
              {user.role && `${t(user.role)} `}
              {user.createdAt &&
                `(${t("dateAdded")}: ${format(
                  new Date(user.createdAt),
                  "dd/MM/yyyy"
                )})`}
            </Text>
          </Box>
        </Flex>
        <Flex alignSelf="start">
          {(user.socialMedia || [])?.map((socialMedia, index) => (
            <>
              {socialMedia?.nick && (
                <SocialMediaIcon
                  key={`sm-${index}`}
                  name={socialMedia.name}
                  nick={socialMedia.nick}
                />
              )}
            </>
          ))}
        </Flex>
      </Flex>
      <Flex align="center" p="1.25rem" justify="space-between">
        <Flex>
          {roles.map((role, index) => (
            <Tooltip
              key={`role-${index}`}
              hasArrow
              label={`${role}`}
              placement="top"
            >
              <Avatar name={`${role}`} ml={index > 0 ? 2 : 0} />
            </Tooltip>
          ))}
          {/* <Tooltip hasArrow label={t("users.moreRoles")} placement="top">
            <Avatar name="…" bg="light.200" color="black" ml={2} />
          </Tooltip> */}
          <Flex justifyContent={"flex-start"}>
            <AvatarGroup>
              {(user.editorialOffices || [])
                .filter((office) => !!office?.editorialOffice)
                .map((office) => (
                  <Avatar
                    key={office?.id}
                    tooltip={office?.editorialOffice?.name}
                    src={
                      office?.editorialOffice?.picture
                        ? `/uploads/${office?.editorialOffice?.picture}`
                        : null
                    }
                    name={office?.editorialOffice?.name}
                    mr={2}
                  />
                ))}
            </AvatarGroup>
          </Flex>
        </Flex>
        <Flex>
          {openMessageModal && (
            <Button size="sm" mr={2} onClick={() => openMessageModal(user.id)}>
              {t("message")}
            </Button>
          )}
          {openInvitationModal && (
            <Button
              size="sm"
              variant="outline"
              onClick={() => openInvitationModal(user.id)}
            >
              {t("office.invite")}
            </Button>
          )}
          {onApprove && user?.status === UserInvitationStatuses.WAITING && (
            <>
              <IconButton
                aria-label={t("accept")}
                variant="green"
                size="sm"
                mr={2}
                icon={<Check />}
                onClick={() => onApprove(user.id, "accept")}
              />
              <IconButton
                aria-label={t("reject")}
                variant="red"
                size="sm"
                icon={<X />}
                onClick={() => onApprove(user.id, "reject")}
              />
            </>
          )}
          {(onEdit ||
            onDelete ||
            onAddToSubscribersCategory ||
            onDeleteFromSubscribersCategory) && (
            <Menu placement="bottom-end">
              <MenuButton role="group" ml={3}>
                <Icon as={MoreVertical} />
              </MenuButton>
              <MenuList>
                {onEdit && (
                  <MenuItem onClick={() => onEdit(user)}>{t("edit")}</MenuItem>
                )}
                {onAddToSubscribersCategory && (
                  <MenuItem
                    onClick={() => onAddToSubscribersCategory(user?.id)}
                  >
                    {t("office.addToCategory")}
                  </MenuItem>
                )}
                {onDeleteFromSubscribersCategory && (
                  <MenuItem
                    onClick={() => onDeleteFromSubscribersCategory(user?.id)}
                  >
                    {t("office.deleteFromCategory")}
                  </MenuItem>
                )}
                {onDelete && (
                  <MenuItem
                    onClick={() => onDelete(user)}
                    isDisabled={session?.user?.id === user?.id}
                  >
                    {t("delete")}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
    </BoxCard>
  );
};

const SocialMediaIcon = ({ name, nick }: SocialMedia): React.ReactElement => (
  <StyledLink href={getSocialMediaURL(nick, name)} isExternal>
    <Flex
      cursor="pointer"
      align="center"
      justify="center"
      w="32px"
      h="32px"
      borderRadius="32px"
      borderWidth="1px"
      borderColor="light.50"
      ml={2}
      transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
      transitionDuration=".3s"
      transitionProperty="transform,box-shadow"
      _hover={{
        transform: "scale(1.05)",
        boxShadow:
          "0 0 transparent,0 0 transparent, 0 20px 25px -5px rgba(0,0,0,0.1), 0 10px 10px -5px rgba(0,0,0,0.04)",
      }}
    >
      <Box w="16px" h="16px" position="relative">
        <Image src={`/${name}.png`} alt="" />
      </Box>
    </Flex>
  </StyledLink>
);

const EditorialOfficeLink = ({
  url,
  children,
}: {
  url: string;
  children: JSX.Element;
}) => {
  if (!url) return children;

  return (
    <Link passHref href={prependURL(url)}>
      <StyledLink isExternal>{children}</StyledLink>
    </Link>
  );
};

export default Card;
